import { ClientOptions, createRpcClient } from "@redotech/rpc/client";
import { InferRpcDefinition } from "@redotech/rpc/definition";
import { createCommentSoldTeamSchema } from "./commentSold/create-team-schema";
import { getMultipassSecretSchema } from "./customer-accounts/get-multipass-secret-schema";
import { setMultipassSecretSchema } from "./customer-accounts/set-multipass-secret-schema";
import { createPhoneNumberSchema } from "./phone-numbers/create-phone-number-schema";
import { getPhoneNumberByTeamIdAndUseCaseSchema } from "./phone-numbers/get-phone-number-by-team-id-and-use-case-schema";
import { updatePhoneNumberSchema } from "./phone-numbers/update-phone-number-schema";

export const adminServerRpc = {
  createCommentSoldTeam: createCommentSoldTeamSchema,
  getMultipassSecret: getMultipassSecretSchema,
  setMultipassSecret: setMultipassSecretSchema,
  getPhoneNumberByTeamIdAndUseCase: getPhoneNumberByTeamIdAndUseCaseSchema,
  createPhoneNumber: createPhoneNumberSchema,
  updatePhoneNumber: updatePhoneNumberSchema,
};

export type AdminServerRpcDefinition = InferRpcDefinition<
  typeof adminServerRpc
>;

export const createAdminRpcClient = (
  options: ClientOptions<typeof adminServerRpc>,
) => createRpcClient(adminServerRpc, options);

export type RedoAdminRpcClient = ReturnType<typeof createAdminRpcClient>;
