import { DiscountValueType } from "@redotech/redo-model/order-discount";
import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import {
  FormSelectDropdown,
  SelectDropdown,
} from "@redotech/redo-web/select-dropdown";
import { SERVICE_LEVELS } from "@redotech/redo-web/service-levels";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input, listInput } from "@redotech/ui/form";
import { memo } from "react";
import * as teamCss from "../../team.module.css";

const outboundLabelPricingForm = groupInput({
  type: input<string>(),
  amount: input<string>(),
  percentage: input<string>(),
});

const outboundLabelPricingFormDefault = (): InputProvider.Value<
  typeof outboundLabelPricingForm
> => ({
  type: DiscountValueType.AMOUNT,
  amount: "0",
  percentage: "0",
});

const serviceLevelForm = groupInput({
  carrier: input<string>(),
  service: input<string>(),
});

const serviceLevelUpchargeForm = groupInput({
  serviceLevel: serviceLevelForm,
  upcharge: outboundLabelPricingForm,
});

const serviceLevelUpchargeFormDefault = (): InputProvider.Value<
  typeof serviceLevelUpchargeForm
> => ({
  serviceLevel: { carrier: "", service: "" },
  upcharge: outboundLabelPricingFormDefault(),
});

export const outboundLabelUpchargesForm = groupInput({
  defaultUpcharge: outboundLabelPricingForm,
  serviceLevelUpcharges: listInput(
    () => serviceLevelUpchargeForm,
    serviceLevelUpchargeFormDefault,
    (i) => i.serviceLevel.carrier + i.serviceLevel.service,
  ),
});

export type OutboundLabelUpchargesForm = InputProvider.Form<
  typeof outboundLabelUpchargesForm
>;

export type OutboundLabelUpchargesValue = InputProvider.Value<
  typeof outboundLabelUpchargesForm
>;

export const outboundLabelUpchargesDefault: OutboundLabelUpchargesValue = {
  defaultUpcharge: outboundLabelPricingFormDefault(),
  serviceLevelUpcharges: [],
};

export const OutboundLabelUpchargesCard = memo(
  function OutboundLabelUpchargesCard({
    input,
  }: {
    input: OutboundLabelUpchargesForm;
  }) {
    const { defaultUpcharge, serviceLevelUpcharges } = input.inputs;
    return (
      <Card title="Outbound Label Upcharges">
        <section className={gridCss.grid}>
          <div className={gridCss.span12}>
            <div className={gridCss.grid}>
              <strong className={gridCss.span6}>Service Level</strong>
              <strong className={gridCss.span3}>Type</strong>
              <strong className={gridCss.span3}>Value</strong>
              <div className={gridCss.span12L} />
            </div>
            <div className={gridCss.grid}>
              <div className={gridCss.span6}>
                <SelectDropdown
                  options={["Default"]}
                  value="Default"
                  valueChange={() => {}}
                >
                  {(option) => option}
                </SelectDropdown>
              </div>
              <div className={gridCss.span3}>
                <FormSelectDropdown
                  input={defaultUpcharge.inputs.type}
                  label=""
                  options={[
                    DiscountValueType.AMOUNT,
                    DiscountValueType.PERCENTAGE,
                  ]}
                >
                  {(option) => option[0].toUpperCase() + option.slice(1)}
                </FormSelectDropdown>
              </div>
              <div className={gridCss.span3}>
                {defaultUpcharge.value.type === DiscountValueType.PERCENTAGE ? (
                  <FormTextInput
                    input={defaultUpcharge.inputs.percentage}
                    label=""
                    max={100}
                    min={0}
                    suffix="%"
                    type="number"
                  />
                ) : (
                  <FormTextInput
                    input={defaultUpcharge.inputs.amount}
                    label=""
                    min={0}
                    prefix="$"
                    type="number"
                  />
                )}
              </div>
              <div className={gridCss.span12L} />
            </div>
            {serviceLevelUpcharges.inputs.map((serviceLevelUpcharge, index) => {
              const { serviceLevel, upcharge } = serviceLevelUpcharge.inputs;
              const { type, amount, percentage } = upcharge.inputs;
              return (
                <div className={gridCss.grid} key={index}>
                  <div className={gridCss.span6}>
                    <FormSelectDropdown
                      input={serviceLevel}
                      label=""
                      options={SERVICE_LEVELS}
                    >
                      {(option) => `${option.carrier} ${option.service}`}
                    </FormSelectDropdown>
                  </div>
                  <div className={gridCss.span3}>
                    <FormSelectDropdown
                      input={type}
                      label=""
                      options={[
                        DiscountValueType.AMOUNT,
                        DiscountValueType.PERCENTAGE,
                      ]}
                    >
                      {(option) => option[0].toUpperCase() + option.slice(1)}
                    </FormSelectDropdown>
                  </div>
                  <div className={gridCss.span3}>
                    {type.value === DiscountValueType.PERCENTAGE ? (
                      <FormTextInput
                        input={percentage}
                        label=""
                        max={100}
                        min={0}
                        suffix="%"
                        type="number"
                      />
                    ) : (
                      <FormTextInput
                        input={amount}
                        label=""
                        min={0}
                        prefix="$"
                        type="number"
                      />
                    )}
                  </div>
                  <div className={gridCss.span12L} />
                </div>
              );
            })}
            <div
              className={teamCss.add}
              onClick={() =>
                serviceLevelUpcharges.setValue([
                  ...serviceLevelUpcharges.value,
                  { ...serviceLevelUpchargeFormDefault() },
                ])
              }
            >
              Add Upcharge <PlusIcon />
            </div>
          </div>
        </section>
      </Card>
    );
  },
);
