// extracted by mini-css-extract-plugin
export var alignBaseline = "_9-8";
export var alignCenter = "_9-4";
export var alignFlexEnd = "_9-6";
export var alignFlexStart = "_9-5";
export var alignSelfBaseline = "_9-d";
export var alignSelfCenter = "_9-9";
export var alignSelfFlexEnd = "_9-b";
export var alignSelfFlexStart = "_9-a";
export var alignSelfStretch = "_9-c";
export var alignStretch = "_9-7";
export var column = "_9-3";
export var flex = "_9-0";
export var justifyCenter = "_9-e";
export var justifyFlexEnd = "_9-g";
export var justifyFlexStart = "_9-f";
export var justifySpaceAround = "_9-i";
export var justifySpaceBetween = "_9-h";
export var justifySpaceEvenly = "_9-j";
export var reverse = "_9-2";
export var row = "_9-1";
export var wrapNowrap = "_9-l";
export var wrapWrap = "_9-k";
export var wrapWrapReverse = "_9-m";