import { Card } from "@redotech/redo-web/card";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { FormSwitch } from "@redotech/redo-web/switch";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo } from "react";

export const supportFaqForm = groupInput({
  enabled: input<boolean>(),
});

export type SupportFaqForm = InputProvider.Form<typeof supportFaqForm>;

export type SupportFaqValue = InputProvider.Value<typeof supportFaqForm>;

export const supportFaqDefault: SupportFaqValue = {
  enabled: false,
};

export const SupportFaqCard = memo(function SupportFaqCard({
  input,
}: {
  input: SupportFaqForm;
}) {
  const { enabled } = input.inputs;

  return (
    <Card title="FAQ">
      <section className={gridCss.grid}>
        <div className={gridCss.span6L}>
          <FormSwitch input={enabled} label="Enabled">
            Enable FAQ product
          </FormSwitch>
        </div>
      </section>
    </Card>
  );
});
