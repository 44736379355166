import { XRequestedWithField } from "@redotech/http/ajax";
import {
  AuthorizationField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { ADMIN_CLIENT_ORIGIN } from "@redotech/redo-model/headers/requested-with-field";
import axios, { AxiosHeaders, AxiosInstance } from "axios";
export class RedoAdminClient {
  readonly client: AxiosInstance;

  constructor(
    baseURL: string,
    private readonly token?: string,
  ) {
    const headers = new AxiosHeaders();
    headers.set(
      XRequestedWithField.name.value,
      `${ADMIN_CLIENT_ORIGIN}/${process.env.REDO_ADMIN_CLIENT_VERSION || "_"}`,
    );
    this.client = axios.create({ baseURL, headers });
  }

  authorization() {
    if (!this.token) {
      throw new Error("Missing token");
    }
    return {
      [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
        bearerCredentialsFormat.write(this.token),
      ),
    };
  }
}
