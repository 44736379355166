import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { defaultFaqTheme } from "@redotech/redo-model/faq/faq-body-schema";
import { TextMessagingBillingDefault } from "@redotech/redo-model/support/billing/text-message-billing";
import { CloseActionMethod, Team } from "@redotech/redo-model/team";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { PageForm, PageFormSave } from "@redotech/redo-web/page-form";
import { groupInput, InputProvider } from "@redotech/ui/form";
import classNames from "classnames";
import { memo, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { RedoAdminClientContext } from "../../client/context";
import { getTeam, updateTeam } from "../../client/team";
import { LoadTeamContext, TeamContext } from "../team";
import * as teamCss from "../team.module.css";
import { SupportAiCard, supportAiDefault, supportAiForm } from "./support/ai";
import {
  SupportFaqCard,
  supportFaqDefault,
  supportFaqForm,
} from "./support/faq";
import {
  SupportGeneralCard,
  supportGeneralDefault,
  supportGeneralForm,
} from "./support/general";
import { ImportZendeskMacrosCard } from "./support/import-zendesk-macros-card";
import { SupportOnboarding } from "./support/support-onboarding";
import {
  supportTextMessagingDefault,
  supportTextMessagingForm,
  TextMessaging,
} from "./support/text-messaging";

export const SupportPage = memo(function SupportPage() {
  const params = useParams();
  const teamId = params.teamId!;

  const team = useContext(TeamContext);
  const loadTeam = useRequiredContext(LoadTeamContext);

  const handleSave = useHandler(() => {
    loadTeam();
  });

  const value: SupportValue | undefined = useMemo(() => {
    if (!team) {
      return;
    }
    const billingSetting = team.settings?.support?.billing;
    const aiBilling = billingSetting?.ai || {};
    const faqEnabled = !!team.settings?.support?.faq?.enabled;
    return {
      general: {
        enabled: !!team.settings?.support,
      },
      ai: {
        enabled: !!team.settings?.support?.ai?.enabled,
        aiPricePerMonth: aiBilling?.pricePerMonth
          ? (aiBilling.pricePerMonth / 100).toString()
          : "",
        aiOveragePrice: aiBilling?.overagePrice
          ? (aiBilling.overagePrice / 100).toString()
          : "",
        aiTicketsCovered: aiBilling?.ticketsCovered?.toString() || "",
        aiFreeTrialDays: aiBilling?.freeTrialDays?.toString() || "0",
        lastDayActive: aiBilling?.lastDayActive
          ? new Date(aiBilling.lastDayActive)
          : null,
      },
      textMessaging: {
        enabled: !!billingSetting?.textMessaging?.enabled,
        pricePerSMSInTenthCents: (
          (billingSetting?.textMessaging?.pricePerSMSInTenthCents || 0) / 1000
        ).toString(),
        pricePerMMSInTenthCents: (
          (billingSetting?.textMessaging?.pricePerMMSInTenthCents || 0) / 1000
        ).toString(),
        monthlyMinimumInTenthCents: (
          (billingSetting?.textMessaging?.monthlyMinimumInTenthCents || 0) /
          1000
        ).toString(),
      },
      faq: {
        enabled: faqEnabled,
      },
    };
  }, [team]);

  return (
    <SupportForm
      id={teamId}
      key={+!team}
      onSave={handleSave}
      team={team}
      value={value}
    />
  );
});

const supportForm = groupInput({
  general: supportGeneralForm,
  ai: supportAiForm,
  textMessaging: supportTextMessagingForm,
  faq: supportFaqForm,
});

export type SupportForm = InputProvider.Form<typeof supportForm>;

export type SupportValue = InputProvider.Value<typeof supportForm>;

const supportDefault: SupportValue = {
  general: supportGeneralDefault,
  ai: supportAiDefault,
  textMessaging: supportTextMessagingDefault,
  faq: supportFaqDefault,
};

const SupportForm = memo(function SupportForm({
  onSave,
  id,
  team,
  value,
}: {
  id: string;
  value: SupportValue | undefined;
  team: Team | undefined;
  onSave?: (value: SupportValue) => void;
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  const input: SupportForm = useInput(supportForm, value || supportDefault);

  const save: PageFormSave = useHandler(async (signal) => {
    const team = await getTeam(client, { teamId: id, signal });

    const value = input.value;
    if (value.general.enabled) {
      team.settings.support = team.settings.support || {
        ticketAssignment: "manual",
        usersForAutoAssignment: [],
        maxTicketsPerUser: "10",
        closeAction: CloseActionMethod.NEXT,
      };
      if (team.settings.support.ai) {
        team.settings.support.ai.enabled = !!value.ai.enabled;
      } else {
        team.settings.support.ai = {
          enabled: !!value.ai.enabled,
          copilotEnabled: false,
          autoGenerateResponses: true,
        };
      }

      team.settings.support.faq = {
        ...(team.settings.support.faq || defaultFaqTheme()),
        enabled: value.faq.enabled,
      };

      const billing = team.settings.support.billing || {};

      let aiBilling = billing.ai || {};

      if (
        (input.inputs.ai.inputs.aiOveragePrice.changed ||
          input.inputs.ai.inputs.aiPricePerMonth.changed ||
          input.inputs.ai.inputs.aiTicketsCovered.changed ||
          input.inputs.ai.inputs.lastDayActive.changed) &&
        value.ai.aiOveragePrice &&
        value.ai.aiPricePerMonth &&
        value.ai.aiTicketsCovered
      ) {
        aiBilling = {
          ...aiBilling,
          freeTrialDays: Number(value.ai.aiFreeTrialDays),
          pricePerMonth: Number(value.ai.aiPricePerMonth) * 100, //store in cents
          overagePrice: Number(value.ai.aiOveragePrice) * 100,
          ticketsCovered: Number(value.ai.aiTicketsCovered),
          planName: "Custom",
          freeTrialUsed: aiBilling.freeTrialUsed || false,
          atShopifyCap: false,
          ticketsUsed: aiBilling.ticketsUsed || 0,
          lastDayActive: value.ai.lastDayActive?.toISOString() || undefined,
        };
      }

      if (value.ai.aiFreeTrialDays) {
        aiBilling.freeTrialDays = Number(value.ai.aiFreeTrialDays);
      }

      if (!billing.textMessaging) {
        billing.textMessaging = { ...TextMessagingBillingDefault };
      }

      billing.textMessaging.enabled = value.textMessaging.enabled;
      billing.textMessaging.pricePerSMSInTenthCents =
        Number(value.textMessaging.pricePerSMSInTenthCents) * 1000;
      billing.textMessaging.pricePerMMSInTenthCents =
        Number(value.textMessaging.pricePerMMSInTenthCents) * 1000;
      billing.textMessaging.monthlyMinimumInTenthCents =
        Number(value.textMessaging.monthlyMinimumInTenthCents) * 1000;

      billing.ai = aiBilling;
      team.settings.support.billing = billing;
    } else {
      team.settings.support = undefined;
    }
    await updateTeam(client, { teamId: id, team, signal });
    onSave && onSave(value);
  });

  const { general, ai, textMessaging, faq } = input.inputs;

  return (
    <div className={teamCss.container}>
      <PageForm initial={value || supportDefault} input={input} save={save}>
        <div className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <SupportGeneralCard input={general} />
              </div>
              <div className={gridCss.span12}>
                <SupportOnboarding />
              </div>
              <div className={gridCss.span12}>
                <SupportFaqCard input={faq} />
              </div>
              <div className={gridCss.span12}>
                <ImportZendeskMacrosCard client={client} teamId={team?._id} />
              </div>
            </div>
          </div>
          <div className={classNames(gridCss.span12, gridCss.span6L)}>
            <div className={gridCss.grid}>
              <div className={gridCss.span12}>
                <SupportAiCard input={ai} />
              </div>
              <div className={gridCss.span12}>
                <TextMessaging input={textMessaging} />
              </div>
            </div>
          </div>
        </div>
      </PageForm>
    </div>
  );
});
