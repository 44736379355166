import { PhoneNumberSchema } from "@redotech/server/rpc/schemas/phone-number";
import { z } from "zod";

export const getPhoneNumberByTeamIdAndUseCaseSchema = {
  input: z.object({
    teamId: z.string(),
    useCase: z.string(),
  }),
  output: PhoneNumberSchema.nullable(),
};
