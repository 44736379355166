import { Card } from "@redotech/redo-web/card";
import { DateInput, MaybeDate } from "@redotech/redo-web/date-picker";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import * as classNames from "classnames";
import { memo } from "react";
import * as aiCss from "./ai.module.css";

export const supportAiForm = groupInput({
  aiFreeTrialDays: input<string>(),
  lastDayActive: input<MaybeDate>(),
  enabled: input<boolean>(),
  aiPricePerMonth: input<string>(),
  aiOveragePrice: input<string>(),
  aiTicketsCovered: input<string>(),
});

export type SupportAiForm = InputProvider.Form<typeof supportAiForm>;

export type SupportAiValue = InputProvider.Value<typeof supportAiForm>;

export const supportAiDefault: SupportAiValue = {
  aiFreeTrialDays: "0",
  lastDayActive: null,
  enabled: false,
  aiPricePerMonth: "",
  aiOveragePrice: "",
  aiTicketsCovered: "",
};

export const SupportAiCard = memo(function SupportAiCard({
  input,
}: {
  input: SupportAiForm;
}) {
  const {
    enabled,
    lastDayActive,
    aiFreeTrialDays,
    aiPricePerMonth,
    aiOveragePrice,
    aiTicketsCovered,
  } = input.inputs;

  return (
    <Card title="AI">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span8L, aiCss.cardItems)}>
          <FormSwitch input={enabled} label="Enabled">
            Enable AI features for Customer Support
          </FormSwitch>
        </div>
      </section>
      <LabeledInput
        description="Setting this date will treat the merchant as if they are active on billing until the date specified. If a merchant cancels their subscription, this date will be set to their last available date, so be sure not to take it away for merchants who have canceled as they've already paid for that month."
        label="Manually enable AI until:"
      >
        <section className={gridCss.grid}>
          <div className={classNames(gridCss.span12, gridCss.span6Xl)}>
            <DateInput
              value={lastDayActive.value}
              valueChange={lastDayActive.setValue}
            />
          </div>
        </section>
      </LabeledInput>
      <h1>AI billing</h1>
      <div>
        If you input a custom plan here the merchant will need to accept billing
        on their end in Shopify. They will see a banner prompting them to do so.
      </div>
      <FormTextInput
        input={aiFreeTrialDays}
        label="Free trial days"
        min={0}
        step={1}
        type="number"
      />
      <FormTextInput
        input={aiPricePerMonth}
        label="Base price per month"
        min={0}
        prefix="$"
        step={0.01}
        type="number"
      />
      <FormTextInput
        input={aiTicketsCovered}
        label="Tickets covered by base cost per month "
        min={0}
        step={1}
        type="number"
      />
      <FormTextInput
        input={aiOveragePrice}
        label="Cost per ticket over base usage"
        min={0}
        prefix="$"
        step={0.01}
        type="number"
      />
    </Card>
  );
});
