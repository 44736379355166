// extracted by mini-css-extract-plugin
export var chip = "_y-9";
export var chipLabel = "_y-a";
export var close = "_y-7";
export var closeIcon = "_y-8";
export var error = "_y-6";
export var infoTooltip = "_y-2";
export var innerContainer = "_y-1";
export var input = "_y-b";
export var medium = "_y-5";
export var outerContainer = "_y-0";
export var small = "_y-4";
export var xSmall = "_y-3";