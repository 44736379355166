import { countries } from "@redotech/locale/countries";
import { Money } from "@redotech/money";
import {
  Currency,
  getCurrencySymbol,
  getNarrowCurrencySymbol,
} from "@redotech/money/currencies";
import { isOpenExchangeRatesCurrency } from "@redotech/money/openexchangerates.org/currencies";
import { IterableMap } from "@redotech/react-util/component";
import { useRequiredContext } from "@redotech/react-util/context";
import { useOnInputChange } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import {
  PaymentModel,
  pricePerOrder as calcPricePerOrder,
  paymentModelMultiplier,
} from "@redotech/redo-model/coverage";
import { getResource } from "@redotech/redo-model/localization/resource";
import { Onboarding } from "@redotech/redo-model/onboarding";
import {
  CollectionSettings,
  ExtendedWarrantyOffering,
  Team,
} from "@redotech/redo-model/team";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import {
  Button,
  ButtonBorder,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { Checkbox, CheckboxGroup } from "@redotech/redo-web/checkbox";
import { ChipDelimiter, FormChipInput } from "@redotech/redo-web/chip-input";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { DateInput } from "@redotech/redo-web/date-picker";
import { Divider } from "@redotech/redo-web/divider";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabelTheme, LabeledInput } from "@redotech/redo-web/labeled-input";
import {
  FormMultiSelectDropdown,
  FormSelectDropdown,
} from "@redotech/redo-web/select-dropdown";
import {
  PriceBracketControls,
  Pricing,
  minMaxValidator,
  pricingDefault,
  pricingFormValues,
  pricingRuleSetDefault,
  pricingRuleSetForm,
  pricingRulesConvertTeamToValue,
  pricingRulesConvertValueToTeam,
} from "@redotech/redo-web/settings-elements/dynamic-pricing";
import {
  FinalSaleReturnsForm,
  finalSaleReturnsForm,
} from "@redotech/redo-web/settings-elements/final-sale";
import { FormSwitch, Switch } from "@redotech/redo-web/switch";
import { Tabs } from "@redotech/redo-web/tab";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { formatDate } from "@redotech/redo-web/time";
import {
  InputProvider,
  ValidationError,
  groupInput,
  input,
  listInput,
  nonEmptyValidator,
  numberValidator,
} from "@redotech/ui/form";
import {
  arrayEqual,
  dateEqual,
  nullableEqual,
  objectEqual,
  stringEqual,
} from "@redotech/util/equal";
import * as classNames from "classnames";
import { produce } from "immer";
import { Fragment, SyntheticEvent, memo, useContext, useState } from "react";
import { RedoAdminClientContext } from "../../../../client/context";
import { getExchangeRate } from "../../../../client/money";
import { getCollections } from "../../../../client/team";
import { TeamContext } from "../../../team";
import * as teamCss from "../../../team.module.css";
const FACTOR_FORMAT = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const PERCENT_FORMAT = new Intl.NumberFormat(undefined, {
  style: "percent",
  maximumFractionDigits: 0,
});

export interface DateRange {
  start: Date | null;
  end: Date | null;
}
export enum ReturnsPricingEnum {
  FIXED = "FIXED",
  PER_ITEM = "PER_ITEM",
  ORDER_TOTAL = "ORDER_TOTAL",
}
export const internationalPricingForm = groupInput({
  id: input<symbol>(),
  countries: input<string[]>(),
  multiplier: input<string>({ validator: numberValidator({ min: 1 }) }),
});

export type InternationalPricingValue = InputProvider.Value<
  typeof internationalPricingForm
>;
export type InternationalPricingForm = InputProvider.Form<
  typeof internationalPricingForm
>;

export const internationalPricingDefault = (
  id: symbol,
): InternationalPricingValue => ({
  id,
  countries: [],
  multiplier: "1.5",
});

const internationalPricingListForm = listInput(
  () => internationalPricingForm,
  internationalPricingDefault,
  (i) => i.id,
);

export type InternationalPricingListForm = InputProvider.Form<
  typeof internationalPricingListForm
>;
export const returnsCoverageForm = groupInput(
  {
    coverage: groupInput({
      credit: input<boolean>(),
      exchange: input<boolean>(),
      refund: input<boolean>(),
    }),
    merchantPricePerOrder: input<string>(),
    excludeCoverageIfAnyInvalidProduct: input<boolean>(),
    paymentModel: input<PaymentModel>(),
    pricePerOrder: input<string>(),
    serviceCountries: input<string[]>({ equal: arrayEqual(stringEqual) }),
    merchantPaidEnabledDates: input<DateRange[]>({
      equal: arrayEqual(
        objectEqual({
          start: nullableEqual(dateEqual),
          end: nullableEqual(dateEqual),
        }),
      ),
      validator: (value) => {
        const errors: ValidationError[] = [];
        value.forEach((range: DateRange) => {
          if (range.start == null) {
            errors.push("Required");
          }
          if (range.start && range.end && range.start > range.end) {
            errors.push("Start must be before end");
          }
        });
        return errors;
      },
    }),
    returnPricingSelection: input<ReturnsPricingEnum>(),
    pricePerAdditonalItem: input<string>({ validator: nonEmptyValidator }),
    dynamicPriceCartValueRuleSet: listInput(
      () => pricingRuleSetForm,
      pricingRuleSetDefault,
      (_, index) => index,
    ),
    maxPrice: input<string>({ validator: nonEmptyValidator }),
    internationalPricing: internationalPricingListForm,
    finalSaleReturns: finalSaleReturnsForm,
  },
  {
    validator: (value) => {
      value.serviceCountries.length === 0;
      const countrySet = new Set(value.serviceCountries);
      let haveAllCountries = value.serviceCountries.length === 0;

      const hasDuplicateCountry = value.internationalPricing.some((pricing) => {
        const empty = pricing.countries.length === 0;
        const hasDuplicateCountry = pricing.countries.some((country) => {
          const hasDuplicate = countrySet.has(country);
          countrySet.add(country);
          return hasDuplicate;
        });

        if (empty && haveAllCountries) {
          return true;
        } else if (empty) {
          haveAllCountries = true;
        }

        return hasDuplicateCountry;
      });

      if (hasDuplicateCountry) {
        return ["Invalid country setup between domestic and international"];
      }
      return [];
    },
  },
);

export type ReturnsCoverageForm = InputProvider.Form<
  typeof returnsCoverageForm
>;

export type ReturnsCoverageValue = InputProvider.Value<
  typeof returnsCoverageForm
>;

export const returnsCoverageDefault: ReturnsCoverageValue = {
  coverage: { credit: false, exchange: false, refund: false },
  excludeCoverageIfAnyInvalidProduct: false,
  merchantPricePerOrder: "",
  merchantPaidEnabledDates: [],
  paymentModel: PaymentModel.CUSTOMER_PAID,
  pricePerOrder: "",
  serviceCountries: [],
  returnPricingSelection: ReturnsPricingEnum.FIXED,
  pricePerAdditonalItem: "",
  maxPrice: "",
  internationalPricing: [],
  dynamicPriceCartValueRuleSet: [
    {
      type: "percentage",
      countries: [],
      priceBrackets: [
        {
          value: "2",
          pricePoint: "0",
        },
      ],
    },
  ],
  finalSaleReturns: {
    enabled: false,
    validCollections: [] as string[],
    validProductTags: [] as string[],
    ...pricingDefault,
  },
};

export const warrantiesForm = groupInput(
  {
    enabled: input<boolean>(),
    customersCanStartClaims: input<boolean>(),
    registrationEnabled: input<boolean>(),
    flatRateRepairEnabled: input<boolean>(),
    flatRateRepairPrice: input<string>(),
    warrantyRegistrationCollection: input<string | undefined>(),
  },
  {},
);

export type WarrantiesForm = InputProvider.Form<typeof warrantiesForm>;

export type WarrantiesValue = InputProvider.Value<typeof warrantiesForm>;

export const warrantiesDefault: WarrantiesValue = {
  enabled: false,
  customersCanStartClaims: false,
  registrationEnabled: false,
  flatRateRepairEnabled: false,
  flatRateRepairPrice: "0",
  warrantyRegistrationCollection: undefined,
};

const InternationalReturnsPricing = memo(function InternationalReturnsPricing({
  pricing,
  countryErrors,
}: {
  pricing: InternationalPricingListForm;
  countryErrors: string[];
}) {
  function deleteRow(id: symbol) {
    return () => {
      pricing.setValue(pricing.value.filter((price) => price.id !== id));
    };
  }

  return (
    <>
      {pricing.value.length > 0 && (
        <div className={classNames(gridCss.grid, gridCss.span12)}>
          <div className={classNames(gridCss.span1)} />
          <div className={classNames(gridCss.span9)}>Countries</div>
          <div className={classNames(gridCss.span2)}>Multiplier</div>
        </div>
      )}
      <IterableMap items={pricing.inputs} keyFn={(_, i) => i}>
        {(input: InternationalPricingForm) => {
          const {
            countries: internationalCountries,
            multiplier,
            id,
          } = input.inputs;
          return (
            <div className={classNames(gridCss.grid, gridCss.span12)}>
              <div className={classNames(gridCss.span1)}>
                <IconButton onClick={deleteRow(id.value)}>
                  <div className={classNames()}>
                    <TrashIcon />
                  </div>
                </IconButton>
              </div>
              <div className={classNames(gridCss.span8)}>
                <FormMultiSelectDropdown
                  display={(value) => {
                    if (value.length === 0) {
                      return "Everywhere else";
                    }
                    if (value.length === countries.length) {
                      return "Everywhere else";
                    }
                    const names = value.map(
                      (code) =>
                        countries.find((country) => country.code === code)
                          ?.name,
                    );
                    return names.join(", ");
                  }}
                  input={internationalCountries}
                  label=""
                  options={countries.map((country: any) => country.code)}
                >
                  {(option) =>
                    countries.find((country) => country.code === option)?.name
                  }
                </FormMultiSelectDropdown>
              </div>
              <div className={classNames(gridCss.span3)}>
                <FormTextInput
                  description=""
                  input={multiplier}
                  label=""
                  prefix="x"
                  type="number"
                />
              </div>
            </div>
          );
        }}
      </IterableMap>

      <div className={classNames(gridCss.span12, teamCss.centered)}>
        <Button
          border={ButtonBorder.LIGHT}
          onClick={() =>
            pricing.setValue([
              ...pricing.value,
              {
                id: Symbol(),
                countries: [],
                multiplier: "1.5",
              },
            ])
          }
          theme={ButtonTheme.OUTLINED}
        >
          Add International Countries
        </Button>
      </div>

      <div className={classNames(gridCss.span12, teamCss.centered)}>
        <span className={classNames(teamCss.errorText)}>
          {countryErrors?.[0]}
        </span>
      </div>
    </>
  );
});

enum LocationSet {
  DOMESTIC = "Domestic",
  INTERNATIONAL = "International",
}

const LocationSetLables = {
  [LocationSet.DOMESTIC]: <>Domestic</>,
  [LocationSet.INTERNATIONAL]: <>International</>,
};

const DynamicReturnsPricingCartValue = memo(
  function DynamicReturnsPricingCartValue({
    input,
  }: {
    input: ReturnsCoverageForm;
  }) {
    const {
      merchantPricePerOrder,
      dynamicPriceCartValueRuleSet,
      maxPrice,
      pricePerOrder,
    } = input.inputs;

    const team = useContext(TeamContext);
    return (
      <div className={classNames(gridCss.span12, gridCss.grid)} dir="column">
        <div className={gridCss.span6L}>
          <FormTextInput
            description="base price of redo"
            input={pricePerOrder}
            label="Min Price"
            prefix={getCurrencySymbol(team?._shopify.currency || Currency.USD)}
            suffix={
              team?._shopify.currency === "USD" ? "" : team?._shopify.currency
            }
          />
        </div>
        <div className={gridCss.span6L}>
          <FormTextInput
            description={`(backend < min) For rev share or (backend > min) for subsidized Coverage. For example if backend price = $.50 and base coverage = $1.00 we'll do a 50% rev share. `}
            input={merchantPricePerOrder}
            label="Backend price"
            placeholder={pricePerOrder.value}
            prefix={getCurrencySymbol(team?._shopify.currency || Currency.USD)}
            suffix={
              team?._shopify.currency === "USD" ? "" : team?._shopify.currency
            }
          />
        </div>
        <div className={classNames(gridCss.span6L)}>
          <FormTextInput
            description=""
            input={maxPrice}
            label="Max price"
            min={+pricePerOrder.value}
            prefix={getCurrencySymbol(team?._shopify.currency || Currency.USD)}
            step={0.01}
            suffix={
              team?._shopify.currency === "USD" ? "" : team?._shopify.currency
            }
            type="number"
          />
        </div>

        <div className={classNames(gridCss.span12)}>
          <PriceBracketControls
            adminApp
            canEditSettings
            index={0}
            minMaxErrors={input.errors}
            pricingRuleSet={dynamicPriceCartValueRuleSet.inputs[0]}
          />
        </div>
      </div>
    );
  },
);

export const ReturnsCoverageCard = memo(function ReturnsCoverageCard({
  input,
  onboarding,
}: {
  input: ReturnsCoverageForm;
  onboarding: Onboarding | undefined;
}) {
  let {
    coverage,
    excludeCoverageIfAnyInvalidProduct,
    merchantPaidEnabledDates,
    merchantPricePerOrder,
    paymentModel,
    pricePerOrder,
    serviceCountries,
    returnPricingSelection,
    pricePerAdditonalItem,
    maxPrice,
    internationalPricing,
  } = input.inputs;
  const { credit, exchange, refund } = coverage.inputs;
  const [property, setProperty] = useState(LocationSet.DOMESTIC);

  const team = useContext(TeamContext);
  const client = useContext(RedoAdminClientContext);

  const exchangeRateLoad = useLoad(async () => {
    if (
      !team?._shopify.currency ||
      team._shopify.currency === Currency.USD ||
      !client ||
      !isOpenExchangeRatesCurrency(team._shopify.currency)
    ) {
      return null;
    }
    const response = await getExchangeRate(
      client,
      Currency.USD,
      team._shopify.currency,
    );
    return response.USD[team._shopify.currency];
  }, [team]);

  const getRatio = (
    params: {
      exchange?: boolean;
      refund?: boolean;
      credit?: boolean;
      paymentModel?: PaymentModel;
    } = {},
  ) => {
    return calcPricePerOrder(
      {
        exchange:
          params.exchange !== undefined
            ? params.exchange
            : input.value.coverage.exchange,
        refund:
          params.refund !== undefined
            ? params.refund
            : input.value.coverage.refund,
        storeCredit:
          params.credit !== undefined
            ? params.credit
            : input.value.coverage.credit,
      },
      params.paymentModel !== undefined
        ? params.paymentModel
        : input.value.paymentModel,
      new Money(1, Currency.USD),
    ).amount;
  };

  const ratio = getRatio();
  const base = +pricePerOrder.value / ratio;

  const adjustPrice = (params: {
    exchange?: boolean;
    refund?: boolean;
    credit?: boolean;
    paymentModel?: PaymentModel;
  }) => {
    const ratio = getRatio(params);
    pricePerOrder.setValue((base * ratio).toFixed(2));
  };

  paymentModel = useOnInputChange(paymentModel, (value) => {
    adjustPrice({ paymentModel: value });
    return value;
  });

  const paymentModelMult = paymentModelMultiplier(paymentModel.value);

  const calcBase =
    onboarding?.orders &&
    ((2 * onboarding.orders.realRefundPercentage) / 100) *
      +onboarding.labelPrice;

  function MerchantPaidEnabledDates() {
    const handleChange = useHandler(
      (date: Date | null, index: number, placement: string) => {
        const { merchantPaidEnabledDates } = input.inputs;
        if (placement === "start") {
          merchantPaidEnabledDates.setValue(
            produce(merchantPaidEnabledDates.value, (dates: any) => {
              dates[index].start = date;
            }),
          );
        } else {
          merchantPaidEnabledDates.setValue(
            produce(merchantPaidEnabledDates.value, (dates: any) => {
              dates[index].end = date;
            }),
          );
        }
      },
    );

    return (
      <>
        {merchantPaidEnabledDates.value.map(
          (range: { start: Date | null; end: Date | null }, index: number) => (
            <div className={teamCss.merchantPaidDateRange} key={index}>
              <div className={teamCss.date}>
                <LabeledInput label="Start">
                  <DateInput
                    value={range.start}
                    valueChange={(val) => handleChange(val, index, "start")}
                  />
                </LabeledInput>
              </div>
              <div className={teamCss.date}>
                <LabeledInput label="End (leave blank for active)">
                  <DateInput
                    value={range.end}
                    valueChange={(val) => handleChange(val, index, "end")}
                  />
                </LabeledInput>
              </div>
            </div>
          ),
        )}
      </>
    );
  }

  return (
    <Card title="Returns coverage">
      <section className={gridCss.grid}>
        <div className={gridCss.span6}>
          <LabeledInput label="Covered return methods">
            <CheckboxGroup>
              <Checkbox onChange={credit.setValue} value={credit.value}>
                Store credit
              </Checkbox>
              <Checkbox onChange={exchange.setValue} value={exchange.value}>
                Exchange
              </Checkbox>
              <Checkbox onChange={refund.setValue} value={refund.value}>
                Refund
              </Checkbox>
            </CheckboxGroup>
          </LabeledInput>
        </div>
        <div className={gridCss.span6}>
          <FormSwitch
            input={excludeCoverageIfAnyInvalidProduct}
            label="Exclude coverage on invalid products"
          >
            Do not offer coverage if any product in the cart is invalid
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12)}>
          <LabeledInput
            description="All dates selected are start of the day in UTC"
            label="Merchant paid coverage"
          >
            {MerchantPaidEnabledDates()}
            <div
              className={teamCss.add}
              onClick={() =>
                merchantPaidEnabledDates.setValue([
                  ...merchantPaidEnabledDates.value,
                  { start: null, end: null },
                ])
              }
            >
              Add date range
              <PlusIcon />
            </div>
          </LabeledInput>
        </div>

        <div className={classNames(gridCss.span12)}>
          <Tabs<LocationSet>
            keyFn={(option) => option}
            options={[LocationSet.DOMESTIC, LocationSet.INTERNATIONAL]}
            tab={(option) => LocationSetLables[option]}
            value={property}
            valueChange={setProperty}
          >
            {property === LocationSet.DOMESTIC ? (
              <div className={classNames(gridCss.grid, gridCss.span12)}>
                <div className={gridCss.span12}>
                  <FormSelectDropdown
                    input={returnPricingSelection}
                    label="Pricing Strategy"
                    options={[...Object.values(ReturnsPricingEnum)]}
                  >
                    {(option) => {
                      switch (option) {
                        case ReturnsPricingEnum.FIXED:
                          return "Single Fixed Price";
                        case ReturnsPricingEnum.ORDER_TOTAL:
                          return "Dynamic: cart total";
                        case ReturnsPricingEnum.PER_ITEM:
                          return "Dynamic: number of items";
                      }
                    }}
                  </FormSelectDropdown>
                </div>

                {returnPricingSelection.value ===
                  ReturnsPricingEnum.PER_ITEM && (
                  <div className={classNames(gridCss.span12, gridCss.grid)}>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description="Price of redo with one item in cart"
                        input={pricePerOrder}
                        label="Base price"
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description={`(backend < base) For rev share or (backend > base) for subsidized Coverage. For example if backend price = $.50 and base coverage = $1.00 we'll do a 50% rev share. `}
                        input={merchantPricePerOrder}
                        label="Backend price"
                        placeholder={pricePerOrder.value}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description=""
                        input={pricePerAdditonalItem}
                        label="Price per additional item"
                        min={0.01}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        step={0.01}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                        type="number"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description=""
                        input={maxPrice}
                        label="Max price"
                        min={+pricePerOrder.value}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        step={0.01}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                        type="number"
                      />
                    </div>
                  </div>
                )}
                {returnPricingSelection.value === ReturnsPricingEnum.FIXED && (
                  <div className={classNames(gridCss.span12, gridCss.grid)}>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description={
                          <>
                            {CURRENCY_FORMAT().format(base)} ×{" "}
                            {FACTOR_FORMAT.format(paymentModelMult)}
                          </>
                        }
                        input={pricePerOrder}
                        label="Coverage price"
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                    <div className={gridCss.span6}>
                      <FormTextInput
                        description="If the merchant subsidizes the price, the full price of coverage. (E.g. customer pays $1.50, merchant contributes $0.50, full price is $1.98)"
                        input={merchantPricePerOrder}
                        label="Backend price"
                        placeholder={pricePerOrder.value}
                        prefix={getCurrencySymbol(
                          team?._shopify.currency || Currency.USD,
                        )}
                        suffix={
                          team?._shopify.currency === "USD"
                            ? ""
                            : team?._shopify.currency
                        }
                        textAlign="left"
                      />
                    </div>
                  </div>
                )}
                {returnPricingSelection.value ===
                  ReturnsPricingEnum.ORDER_TOTAL && (
                  <DynamicReturnsPricingCartValue input={input} />
                )}
                <div className={classNames(gridCss.span12)}>
                  <FormMultiSelectDropdown
                    display={(value) => {
                      if (value.length === 0) {
                        return "All";
                      }
                      if (value.length === countries.length) {
                        return "All";
                      }
                      const names = value.map(
                        (code) =>
                          countries.find((country) => country.code === code)
                            ?.name,
                      );
                      return names.join(", ");
                    }}
                    input={serviceCountries}
                    label="Domestic countries"
                    options={countries.map((country: any) => country.code)}
                  >
                    {(option) =>
                      countries.find((country) => country.code === option)?.name
                    }
                  </FormMultiSelectDropdown>
                </div>
                <div className={classNames(gridCss.span12, teamCss.centered)}>
                  <span className={classNames(teamCss.errorText)}>
                    {input.allErrors?.[0]}
                  </span>
                </div>
              </div>
            ) : (
              <div className={classNames(gridCss.span12, teamCss.flexOne)}>
                <InternationalReturnsPricing
                  countryErrors={input.allErrors}
                  pricing={internationalPricing}
                />
              </div>
            )}
          </Tabs>
        </div>

        <div className={gridCss.span12}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>
            {onboarding?.finished
              ? "Onboarding order statistics"
              : "Order statistics not yet calculated"}
          </strong>
        </div>
        <div className={gridCss.span12}>
          <dl className={teamCss.table}>
            <dt className={teamCss.tableHeader}>Start</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                formatDate(Temporal.Instant.from(onboarding.orders.fromDate))}
            </dd>
            <dt className={teamCss.tableHeader}>End</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                formatDate(Temporal.Instant.from(onboarding.orders.toDate))}
            </dd>
            <dt className={teamCss.tableHeader}>Orders</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                new Intl.NumberFormat().format(onboarding.orders.count)}
            </dd>
            <dt className={teamCss.tableHeader}>Refunded orders</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                new Intl.NumberFormat().format(onboarding.orders.countRefunded)}
            </dd>
            <dt className={teamCss.tableHeader}>Refund rate</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                PERCENT_FORMAT.format(onboarding.orders.refundPercentage / 100)}
            </dd>
            <dt className={teamCss.tableHeader}>Estimated return rate</dt>
            <dd className={teamCss.tableCell}>
              {onboarding?.orders &&
                PERCENT_FORMAT.format(
                  onboarding.orders.realRefundPercentage / 100,
                )}
            </dd>
            <dt className={teamCss.tableHeader}>Price per label</dt>
            <dd className={teamCss.tableCell}>
              {onboarding && CURRENCY_FORMAT().format(+onboarding.labelPrice)}
            </dd>
            <dt className={teamCss.tableHeader}>Calculated base</dt>
            <dd className={teamCss.tableCell}>
              {calcBase !== undefined && CURRENCY_FORMAT().format(calcBase)}
            </dd>
            <dt className={teamCss.tableHeader}>Calculated price</dt>
            <dd className={teamCss.tableCell}>
              {calcBase !== undefined &&
                CURRENCY_FORMAT().format(ratio * calcBase)}{" "}
            </dd>
            {team?._shopify.currency &&
              team._shopify.currency !== Currency.USD &&
              exchangeRateLoad.value && (
                <>
                  <dt className={teamCss.tableHeader}>
                    Calculated price in {team?._shopify.currency}{" "}
                  </dt>
                  <dd className={teamCss.tableCell}>
                    {calcBase !== undefined &&
                      CURRENCY_FORMAT(team?._shopify.currency).format(
                        ratio * calcBase * exchangeRateLoad.value,
                      )}{" "}
                  </dd>
                </>
              )}
          </dl>
        </div>
      </section>
    </Card>
  );
});

export const FinalSaleReturnsCard = memo(function FinalSaleReturnsCard({
  input,
}: {
  input: FinalSaleReturnsForm;
}) {
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);

  const [searchString, setSearchString] = useState<string>();

  const collectionsLoad = useLoad(async () => {
    if (!team) {
      return { collections: [] };
    }

    return { collections: await getCollections(client, { teamId: team._id }) };
  }, []);

  const collections = collectionsLoad.value?.collections ?? [];

  const selectedCollections = collections.filter((collection) =>
    input.value.validCollections?.includes(collection.handle),
  );

  const searchedCollections = searchString
    ? collections.filter((collection) =>
        collection.title
          .toLowerCase()
          .includes(searchString?.toLowerCase() || ""),
      )
    : collections;

  const collectionsLoading = collectionsLoad.pending && !collectionsLoad.value;

  const handleValueChange = useHandler(
    (collections: { id: string; title: string; handle: string }[]) => {
      input.inputs.validCollections.setValue(
        collections.map((collection) => collection.handle),
      );
    },
  );

  const handleInputChange = useHandler(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchString(value);
    },
  );

  return (
    <Card title="Final sale returns by Redo">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <FormSwitch input={input.inputs.enabled} label="Coverage Enabled">
            Enable customers to buy coverage
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <FormChipInput
            delimiter={ChipDelimiter.NEWLINE}
            description="Coverage will be added for these Shopify order tags (e.g. wholesale)."
            input={input.inputs.validProductTags}
            label="Included order tags"
            trimWhitespace
          />
        </div>
        <div className={gridCss.span12}>
          <LabeledInput
            description="Coverage will be offered for these Shopify collections. The first 100 collections are shown by default. To access more, search by title."
            label="Included collections"
          >
            <Autocomplete
              getLabel={(collection) => collection.title}
              keyFn={(collection) => collection.handle}
              multiple
              noOptionsText={collectionsLoading ? "Loading..." : "No options"}
              onInputChange={handleInputChange}
              options={searchedCollections}
              value={selectedCollections}
              valueChange={handleValueChange}
            >
              {(collection) => collection.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12)}>
          <Divider />
        </div>
        <div className={classNames(gridCss.span12)}>
          <strong>Pricing</strong>
        </div>
        <div className={gridCss.span12}>
          <Pricing
            adminApp
            canEditSettings
            currencyDisplay={getCurrencySymbol(
              team?._shopify.currency || Currency.USD,
            )}
            input={input}
          />
        </div>
      </section>
    </Card>
  );
});

export const Warranties = memo(function Warranties({
  input,
}: {
  input: WarrantiesForm;
}) {
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);

  const [searchString, setSearchString] = useState<string>();

  const collectionsLoad = useLoad(async () => {
    if (!team) {
      return { collections: [] };
    }

    return { collections: await getCollections(client, { teamId: team._id }) };
  }, []);

  const collections = collectionsLoad.value?.collections ?? [];

  const selectedCollection = collections.find(
    (collection) =>
      collection.id === input.value.warrantyRegistrationCollection,
  );

  const searchedCollections = searchString
    ? collections.filter((collection) =>
        collection.title
          .toLowerCase()
          .includes(searchString?.toLowerCase() || ""),
      )
    : collections;

  const collectionsLoading = collectionsLoad.pending && !collectionsLoad.value;

  const handleToggle = useHandler((value: boolean) => {
    input.inputs.enabled.setValue(value);
    if (!value) {
      input.inputs.flatRateRepairEnabled.setValue(false);
      input.inputs.customersCanStartClaims.setValue(false);
      input.inputs.registrationEnabled.setValue(false);
    }
  });

  const handleValueChange = useHandler(
    (collection: { id: string; title: string; handle: string } | null) => {
      input.inputs.warrantyRegistrationCollection.setValue(collection?.id);
    },
  );

  const handleInputChange = useHandler(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchString(value);
    },
  );

  return (
    <Card title="Warranties">
      <section className={gridCss.grid}>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <LabeledInput
            description="Display the warranty flow to merchants"
            label="Warranty flow"
          >
            <Switch
              onChange={handleToggle}
              value={input.inputs.enabled.value}
            />
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={input.inputs.customersCanStartClaims}
            label="Allows customers to start claims"
          >
            Show the '{getResource({ variant: "warranty" }).PORTAL_BUTTON_TEXT}'
            button on the customer portal.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={input.inputs.registrationEnabled}
            label="Warranty registration"
          >
            Allow customers to register their warranties and submit claims on
            them.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <LabeledInput label="Registration collection">
            <Autocomplete
              getLabel={(collection) => collection.title}
              keyFn={(collection) => collection.handle}
              noOptionsText={collectionsLoading ? "Loading..." : "No options"}
              onInputChange={handleInputChange}
              options={searchedCollections}
              value={selectedCollection ?? null}
              valueChange={handleValueChange}
            >
              {(collection) => collection.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <FormSwitch
            input={input.inputs.flatRateRepairEnabled}
            label="Flat rate repairs"
          >
            Charge customers a flat rate for repairs.
          </FormSwitch>
        </div>
        <div className={classNames(gridCss.span12, gridCss.span6M)}>
          <LabeledInput label="Flat rate" theme={LabelTheme.THIN}>
            <FormTextInput
              description="Flat price for a repair. Price is per item in shop currency."
              input={input.inputs.flatRateRepairPrice}
              label=""
              prefix={getNarrowCurrencySymbol(
                team?._shopify.currency || Currency.USD,
              )}
            />
          </LabeledInput>
        </div>
      </section>
    </Card>
  );
});

export const extendedWarrantyOfferingForm = groupInput(
  {
    timeId: input<string>(),
    timeTitle: input<string>(),
    ...pricingFormValues,
  },
  { validator: minMaxValidator },
);

export const extendedWarrantyCollectionForm = groupInput({
  collectionHandle: input<string>(),
  collectionName: input<string>(),
  validOfferings: input<string[]>({ equal: arrayEqual(stringEqual) }),
  offerings: listInput(
    () => extendedWarrantyOfferingForm,
    () => ({
      timeId: "",
      timeTitle: "",
      collectionHandle: "",
      collectionName: "",
      ...pricingDefault,
    }),
    (_, index) => index,
  ),
});

export const extendedWarrantySettingsForm = groupInput({
  enabled: input<boolean>(),
  variantSelector: input<string>(),
  validCollections: input<string[]>({ equal: arrayEqual(stringEqual) }),
  collectionSettings: listInput(
    () => extendedWarrantyCollectionForm,
    () => ({
      collectionHandle: "",
      collectionName: "",
      validOfferings: [],
      offerings: [],
    }),
    (_, index) => index,
  ),
});

export type ExtendedWarrantyCollectionValue = InputProvider.Value<
  typeof extendedWarrantyCollectionForm
>;
export type ExtendedWarrantyCollectionForm = InputProvider.Form<
  typeof extendedWarrantyCollectionForm
>;

export type ExtendedWarrantySettingsForm = InputProvider.Form<
  typeof extendedWarrantySettingsForm
>;

export type ExtendedWarrantySettingsValue = InputProvider.Value<
  typeof extendedWarrantySettingsForm
>;

export const extendedWarrantySettingsDefault = {
  enabled: false,
  variantSelector: "",
  validCollections: [],
  collectionSettings: [],
};

export function extendedWarrantyTeamToFormValue(
  team?: Team,
): ExtendedWarrantySettingsValue {
  return {
    enabled: team?.settings.extendedWarranties?.enabled || false,
    variantSelector: team?.settings.extendedWarranties?.variantSelector || "",
    validCollections: team?.settings.extendedWarranties?.validCollections || [],
    collectionSettings:
      team?.settings.extendedWarranties?.collectionSettings?.map(
        (collectionSettings) => ({
          collectionHandle: collectionSettings.collectionHandle,
          collectionName: collectionSettings.collectionName || "",
          validOfferings: collectionSettings.validOfferings || [],
          offerings: collectionSettings.offerings.map((offering) => ({
            timeId: offering.timeId,
            timeTitle: offering.timeTitle,
            minPrice: offering.minPrice,
            maxPrice: offering.maxPrice,
            percentage: offering.percentage,
            internationalPricingEnabled: offering.internationalPricingEnabled,
            pricingRuleSet: pricingRulesConvertTeamToValue([
              offering.pricingRuleSet,
            ]),
          })),
        }),
      ) || [],
  };
}

export function extendedWarrantyFormValueToTeam(
  extendedWarranties: ExtendedWarrantySettingsValue,
) {
  return {
    enabled: extendedWarranties.enabled,
    variantSelector: extendedWarranties.variantSelector,
    validCollections: extendedWarranties.validCollections,
    collectionSettings: extendedWarranties.collectionSettings.map(
      (collectionSettings) => ({
        ...collectionSettings,
        offerings: collectionSettings.offerings.map((offering) => ({
          ...offering,
          pricingRuleSet: pricingRulesConvertValueToTeam(
            offering.pricingRuleSet,
          )?.[0],
        })) as ExtendedWarrantyOffering[],
      }),
    ) as CollectionSettings[],
  };
}

export const ExtendedWarranties = memo(function ExtendedWarranties({
  input,
}: {
  input: ExtendedWarrantySettingsForm;
}) {
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);
  const [searchString, setSearchString] = useState<string>();
  const collectionsLoad = useLoad(async () => {
    if (!team) {
      return { collections: [] };
    }

    return { collections: await getCollections(client, { teamId: team._id }) };
  }, []);

  const collections = collectionsLoad.value?.collections ?? [];
  const selectedCollections = collections.filter((collection) =>
    input.value.validCollections?.includes(collection.handle),
  );
  const searchedCollections = searchString
    ? collections.filter((collection) =>
        collection.title
          .toLowerCase()
          .includes(searchString?.toLowerCase() || ""),
      )
    : collections;
  const collectionsLoading = collectionsLoad.pending && !collectionsLoad.value;
  const hasSufficientAccess = team?.accessScope.includes(
    "write_cart_transforms",
  );

  const handleValueChange = useHandler(
    (collections: { id: string; title: string; handle: string }[]) => {
      input.inputs.validCollections.setValue(
        collections.map((collection) => collection.handle),
      );
      input.inputs.collectionSettings.setValue(
        collections.map((collection) => ({
          collectionHandle: collection.handle,
          collectionName: collection.title,
          validOfferings:
            input.value.collectionSettings.find(
              (collectionSettings) =>
                collectionSettings.collectionHandle === collection.handle,
            )?.validOfferings || [],
          offerings:
            input.value.collectionSettings.find(
              (collectionSettings) =>
                collectionSettings.collectionHandle === collection.handle,
            )?.offerings || [],
        })),
      );
    },
  );

  const handleInputChange = useHandler(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchString(value);
    },
  );

  return (
    <Card title="Extended warranties">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <FormSwitch
            disabled={!hasSufficientAccess}
            input={input.inputs.enabled}
            label="Coverage Enabled"
          >
            Enable customers to buy extended warranties
          </FormSwitch>
          {!hasSufficientAccess && (
            <Text fontSize="sm" pt="sm">
              The team does not have sufficient access to enable extended
              warranties. Please ask an admin to update the team's permissions
              through the merchant portal.
            </Text>
          )}
        </div>

        <div className={gridCss.span12}>
          <FormTextInput
            description="You can use %variant_id% as placeholder."
            input={input.inputs.variantSelector}
            label="Variant selector for the extended warranty toggle."
          />
        </div>

        <div className={gridCss.span12}>
          <LabeledInput
            description="Coverage will be offered for these Shopify collections. The first 100 collections are shown by default. To access more, search by title."
            label="Included collections"
          >
            <Autocomplete
              disabled={!hasSufficientAccess}
              getLabel={(collection) => collection.title}
              keyFn={(collection) => collection.handle}
              multiple
              noOptionsText={collectionsLoading ? "Loading..." : "No options"}
              onInputChange={handleInputChange}
              options={searchedCollections}
              value={selectedCollections}
              valueChange={handleValueChange}
            >
              {(collection) => collection.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        {input.value.collectionSettings.map((collectionSettings, index) => (
          <ExtendedWarrantiesCollection
            input={input.inputs.collectionSettings.inputs[index]}
            key={collectionSettings.collectionHandle}
          />
        ))}
      </section>
    </Card>
  );
});

export const ExtendedWarrantiesCollection = memo(
  function ExtendedWarrantiesCollection({
    input,
  }: {
    input: ExtendedWarrantyCollectionForm;
  }) {
    const tileOptions: { id: string; title: string; handle: string }[] = [];
    for (let i = 1; i <= 10; i++) {
      tileOptions.push({
        id: i.toString(),
        title: i === 1 ? "1 year" : `${i} years`,
        handle: i.toString(),
      });
    }
    tileOptions.push({
      id: "lifetime",
      title: "Lifetime",
      handle: "lifetime",
    });
    const team = useContext(TeamContext);
    const selectedTileOptions = tileOptions.filter((option) =>
      input.value.offerings.some((offering) => offering.timeId === option.id),
    );

    const handleValueChange = useHandler(
      (tileOptions: { id: string; title: string; handle: string }[]) => {
        input.inputs.validOfferings.setValue(
          tileOptions.map((option) => option.id),
        );
        input.inputs.offerings.setValue(
          tileOptions.map((option, index) => ({
            timeId: option.id,
            timeTitle: option.title,
            collectionHandle: input.value.collectionHandle,
            collectionName: input.value.collectionName,
            ...(input.inputs.offerings.value.some(
              (offering) => offering.timeId === option.id,
            )
              ? input.inputs.offerings.inputs[index].value
              : pricingDefault),
          })),
        );
      },
    );

    return (
      <>
        <div className={classNames(gridCss.span12)}>
          <Divider />
        </div>
        <div className={gridCss.span12}>
          <strong>{input.value.collectionName}</strong>
        </div>

        <div className={gridCss.span12}>
          <LabeledInput
            description="These are the tiles offered to customers."
            label={`Warranty offerings for ${input.value.collectionName}`}
          >
            <Autocomplete
              getLabel={(option) => option.title}
              keyFn={(option) => option.handle}
              multiple
              options={tileOptions}
              value={selectedTileOptions}
              valueChange={handleValueChange}
            >
              {(option) => option.title}
            </Autocomplete>
          </LabeledInput>
        </div>
        {input.value.offerings.map((offering, index) => (
          <Fragment key={offering.timeTitle}>
            <div className={gridCss.span12}>
              <Divider />
            </div>
            <div className={gridCss.span12}>
              <strong>{offering.timeTitle}</strong>
            </div>
            <div className={gridCss.span12}>
              <Pricing
                adminApp
                canEditSettings
                currencyDisplay={getCurrencySymbol(
                  team?._shopify.currency || Currency.USD,
                )}
                input={input.inputs.offerings.inputs[index]}
              />
            </div>
          </Fragment>
        ))}
      </>
    );
  },
);
