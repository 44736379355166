import { composeWrappers } from "@redotech/react-util/component";
import { useBreadcrumbs } from "@redotech/redo-web/breadcrumb";
import { CustomSnackbarProvider } from "@redotech/redo-web/snackbar";
import { ThemeProvider } from "@redotech/redo-web/theme-provider";
import * as Sentry from "@sentry/react";
import { memo } from "react";
import { createRoot } from "react-dom/client";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import { AuthContext, AuthProvider } from "./app/auth";
import { RedoAdminRpcClientProvider } from "./app/redo-admin-rpc-client-provider";
import { UserProvider } from "./app/user";
import { RedoAdminClient } from "./client";
import { RedoAdminClientContext } from "./client/context";
import { REDO_ADMIN_SERVER_URL, SENTRY_DSN } from "./config";
import { loginRoutes } from "./login/route";
import "./styles.css";
import { teamRoutes } from "./team/route";

if (SENTRY_DSN) {
  Sentry.init({ dsn: SENTRY_DSN });
}

const RouterRoot = memo(function RouterRoot() {
  const breadcrumbs = useBreadcrumbs();
  useDocumentTitle(
    breadcrumbs
      ? `${breadcrumbs
          .map((breadcrumb) => breadcrumb.name)
          .join(" ")} — Redo Admin`
      : "Redo Admin",
  );
  return <Outlet />;
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <RouterRoot />,
    // errorElement: <div>Not Found</div>,
    children: [
      {
        index: true,
        element: <Navigate to="login" />,
      },
      {
        path: "login",
        element: <Outlet />,
        children: loginRoutes,
      },
      {
        path: "teams",
        element: <Outlet />,
        children: teamRoutes,
      },
    ],
  },
]);

const AdminApp = () => {
  return composeWrappers(
    (inner) => <CustomSnackbarProvider>{inner}</CustomSnackbarProvider>,
    (inner) => <AuthProvider>{inner}</AuthProvider>,
    (inner) => <ThemeProvider>{inner}</ThemeProvider>,
    (inner) => (
      <AuthContext.Consumer>
        {(auth) => (
          <RedoAdminRpcClientProvider
            authToken={auth?.token}
            baseUrl={REDO_ADMIN_SERVER_URL}
          >
            <RedoAdminClientContext.Provider
              value={new RedoAdminClient(REDO_ADMIN_SERVER_URL, auth?.token)}
            >
              <UserProvider>{inner}</UserProvider>
            </RedoAdminClientContext.Provider>
          </RedoAdminRpcClientProvider>
        )}
      </AuthContext.Consumer>
    ),
  )(<RouterProvider router={router} />);
};

const root = createRoot(document.getElementById("root")!);
root.render(<AdminApp />);
