import { ServiceLevel } from "@redotech/redo-model/outbound-labels";

export const SERVICE_LEVELS: ServiceLevel[] = [
  {
    carrier: "Sendle",
    service: "SAVER-PICKUP",
  },
  {
    carrier: "Sendle",
    service: "SAVER-DROPOFF",
  },
  {
    carrier: "Sendle",
    service: "STANDARD-PICKUP",
  },
  {
    carrier: "Sendle",
    service: "STANDARD-DROPOFF",
  },
  {
    carrier: "Sendle",
    service: "THREE-DAY-PICKUP",
  },
  {
    carrier: "Sendle",
    service: "THREE-DAY-DROPOFF",
  },
  {
    carrier: "Sendle",
    service: "GROUND-ADVANTAGE-PICKUP",
  },
  {
    carrier: "Sendle",
    service: "GROUND-ADVANTAGE-DROPOFF",
  },
  {
    carrier: "Sendle",
    service: "PRIORITY-PICKUP",
  },
  {
    carrier: "Sendle",
    service: "PRIORITY-DROPOFF",
  },
  {
    carrier: "Sendle",
    service: "PRIORITY-EXPRESS-PICKUP",
  },
  {
    carrier: "Sendle",
    service: "PRIORITY-EXPRESS-DROPOFF",
  },
  {
    carrier: "Sendle",
    service: "TWO-DAY-PICKUP",
  },
  {
    carrier: "Sendle",
    service: "TWO-DAY-DROPOFF",
  },
  {
    carrier: "USPS",
    service: "First",
  },
  {
    carrier: "USPS",
    service: "Priority",
  },
  {
    carrier: "USPS",
    service: "ParcelSelect",
  },
  {
    carrier: "USPS",
    service: "GroundAdvantage",
  },
  {
    carrier: "USPS",
    service: "Express",
  },
  {
    carrier: "USPSReturns",
    service: "GroundAdvantageReturn",
  },
  {
    carrier: "USPSReturns",
    service: "FirstClassPkgReturn",
  },
  {
    carrier: "USPSReturns",
    service: "GroundReturn",
  },
  {
    carrier: "USPSReturns",
    service: "PriorityMailReturn",
  },
  {
    carrier: "USPSReturns",
    service: "PriorityMailExpressReturn",
  },
  {
    carrier: "UPS",
    service: "Ground",
  },
  {
    carrier: "UPS",
    service: "NextDayAir",
  },
  {
    carrier: "UPS",
    service: "NextDayAirSaver",
  },
  {
    carrier: "UPS",
    service: "NextDayAirEarlyAM",
  },
  {
    carrier: "UPS",
    service: "2ndDayAir",
  },
  {
    carrier: "UPS",
    service: "3DaySelect",
  },
  {
    carrier: "FedEx",
    service: "GROUND_HOME_DELIVERY",
  },
  {
    carrier: "FedEx",
    service: "FEDEX_EXPRESS_SAVER",
  },
  {
    carrier: "FedEx",
    service: "FEDEX_2_DAY",
  },
  {
    carrier: "FedEx",
    service: "FEDEX_2_DAY_AM",
  },
  {
    carrier: "FedEx",
    service: "STANDARD_OVERNIGHT",
  },
  {
    carrier: "FedEx",
    service: "PRIORITY_OVERNIGHT",
  },
  {
    carrier: "FedEx",
    service: "FIRST_OVERNIGHT",
  },
  {
    carrier: "DhlEcs",
    service: "DHLSmartMailParcelReturnGround",
  },
  {
    carrier: "DhlEcs",
    service: "DHLSmartMailParcelReturnPlus",
  },
  {
    carrier: "DhlEcs",
    service: "DHLSmartMailParcelReturnLight",
  },
  {
    carrier: "DhlEcs",
    service: "DHLSmartMailParcelReturnGround",
  },
];
