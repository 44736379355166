export const REDO_ADMIN_SERVER_URL =
  process.env.REDO_ADMIN_SERVER_URL || "http://localhost:8005/";

export const REDO_MERCHANT_APP_URL =
  process.env.REDO_MERCHANT_APP_URL || "http://localhost:3003";

export const SENTRY_DSN = process.env.SENTRY_DSN || "";

export const REDO_CHAT_WIDGET_URL =
  process.env.REDO_CHAT_WIDGET_URL || "http://localhost:3008";
