// extracted by mini-css-extract-plugin
export var grid = "_7-0";
export var span1 = "_7-2";
export var span10 = "_7-b";
export var span10L = "_7-z";
export var span10M = "_7-n";
export var span10Xl = "_7-1b";
export var span11 = "_7-c";
export var span11L = "_7-10";
export var span11M = "_7-o";
export var span11Xl = "_7-1c";
export var span12 = "_7-d";
export var span12L = "_7-11";
export var span12M = "_7-p";
export var span12Xl = "_7-1d";
export var span1L = "_7-q";
export var span1M = "_7-e";
export var span1Xl = "_7-12";
export var span2 = "_7-3";
export var span2L = "_7-r";
export var span2M = "_7-f";
export var span2Xl = "_7-13";
export var span3 = "_7-4";
export var span3L = "_7-s";
export var span3M = "_7-g";
export var span3Xl = "_7-14";
export var span4 = "_7-5";
export var span4L = "_7-t";
export var span4M = "_7-h";
export var span4Xl = "_7-15";
export var span5 = "_7-6";
export var span5L = "_7-u";
export var span5M = "_7-i";
export var span5Xl = "_7-16";
export var span6 = "_7-7";
export var span6L = "_7-v";
export var span6M = "_7-j";
export var span6Xl = "_7-17";
export var span7 = "_7-8";
export var span7L = "_7-w";
export var span7M = "_7-k";
export var span7Xl = "_7-18";
export var span8 = "_7-9";
export var span8L = "_7-x";
export var span8M = "_7-l";
export var span8Xl = "_7-19";
export var span9 = "_7-a";
export var span9L = "_7-y";
export var span9M = "_7-m";
export var span9Xl = "_7-1a";
export var stretch = "_7-1";