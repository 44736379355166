// extracted by mini-css-extract-plugin
export var actions = "_c-0";
export var actionsContainer = "_c-3";
export var border = "_c-1";
export var collapse = "_c-2";
export var compact = "_c-b";
export var content = "_c-4";
export var contentChild = "_c-8";
export var fullscreen = "_c-6";
export var header = "_c-9";
export var headerContainer = "_c-d";
export var hideHeaderBorder = "_c-c";
export var hidePadding = "_c-5";
export var noScrollX = "_c-e";
export var noScrollY = "_c-f";
export var optOutTopPadding = "_c-7";
export var profile = "_c-g";
export var profileContainer = "_c-h";
export var profileImage = "_c-i";
export var profileMain = "_c-j";
export var profileSubtitle = "_c-l";
export var profileTitle = "_c-k";
export var scrolledTop = "_c-a";
export var title = "_c-m";