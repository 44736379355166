import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Card } from "@redotech/redo-web/card";
import { ChipDelimiter, FormChipInput } from "@redotech/redo-web/chip-input";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { FormRadioGroup, RadioGroupLayout } from "@redotech/redo-web/radio";
import { FormSwitch } from "@redotech/redo-web/switch";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input, InputProvider } from "@redotech/ui/form";
import { arrayEqual, stringEqual } from "@redotech/util/equal";
import { memo, SyntheticEvent, useContext, useState } from "react";
import { RedoAdminClientContext } from "../../../client/context";
import { getCollections } from "../../../client/team";
import { TeamContext } from "../../team";

export const returnProcessingForm = groupInput({
  shopAgain: input<
    "gift_card" | "discount_code" | "rise" | "shopify_account"
  >(),
  riseTriggerUrl: input<string>(),
  portalExcludedProductTags: input({ equal: arrayEqual(stringEqual) }),
  portalExcludedCollections: input({ equal: arrayEqual(stringEqual) }),
  allowReopen: input<boolean>(),
  hideGiftCardBranding: input<boolean>(),
  allowRestockBeforeProcess: input<boolean>(),
});

export type ReturnProcessingForm = InputProvider.Form<
  typeof returnProcessingForm
>;

export type ReturnProcessingValue = InputProvider.Value<
  typeof returnProcessingForm
>;

export const returnProcessingDefault: ReturnProcessingValue = {
  riseTriggerUrl: "",
  shopAgain: "gift_card",
  portalExcludedProductTags: [],
  portalExcludedCollections: [],
  allowReopen: false,
  hideGiftCardBranding: false,
  allowRestockBeforeProcess: false,
};

export const ReturnProcessingCard = memo(function ReturnProcessingCard({
  input,
}: {
  input: ReturnProcessingForm;
}) {
  const {
    shopAgain,
    riseTriggerUrl,
    portalExcludedProductTags,
    portalExcludedCollections,
    allowReopen,
    hideGiftCardBranding,
    allowRestockBeforeProcess,
  } = input.inputs;

  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoAdminClientContext);

  const [searchString, setSearchString] = useState<string>();

  const collectionsLoad = useLoad(async () => {
    if (!team) {
      return { collections: [] };
    }

    return {
      collections: (await getCollections(client, { teamId: team._id })).map(
        (collections) => collections.title,
      ),
    };
  }, []);

  const collections = collectionsLoad.value?.collections ?? [];

  const searchedCollections = searchString
    ? collections.filter((collection) =>
        collection.toLowerCase().includes(searchString?.toLowerCase() || ""),
      )
    : collections;

  const collectionsLoading = collectionsLoad.pending && !collectionsLoad.value;

  const handleInputChange = useHandler(
    (event: SyntheticEvent<Element, Event>, value: string) => {
      setSearchString(value);
    },
  );
  return (
    <Card title="Return processing">
      <section className={gridCss.grid}>
        <div className={gridCss.span12}>
          <FormRadioGroup
            input={shopAgain}
            label="Store credit type"
            layout={RadioGroupLayout.HORIZONTAL}
            optionLabel={(option) =>
              ({
                gift_card: "Gift card",
                discount_code: "Discount code",
                rise: "Rise credit",
                shopify_account: "Shopify account",
              })[option]
            }
            options={["gift_card", "discount_code", "rise", "shopify_account"]}
          >
            Type of store credit to issue
          </FormRadioGroup>
        </div>
        {shopAgain.value === "rise" && (
          <div className={gridCss.span12}>
            <FormTextInput
              description="Rise trigger URL"
              input={riseTriggerUrl}
              label="URL of Rise custom trigger"
            />
          </div>
        )}
        <div className={gridCss.span12}>
          <FormChipInput
            delimiter={ChipDelimiter.NEWLINE}
            description="Product tags to exclude from the customer portal"
            input={portalExcludedProductTags}
            label="Excluded product tags"
            trimWhitespace
          />
        </div>
        <div className={gridCss.span12}>
          <LabeledInput
            description="collections to exclude from the customer portal"
            label="Excluded Collections"
          >
            <Autocomplete
              getLabel={(collection) => collection}
              keyFn={(collection) => collection}
              multiple
              noOptionsText={collectionsLoading ? "Loading..." : "No options"}
              onInputChange={handleInputChange}
              options={searchedCollections}
              value={portalExcludedCollections.value as string[]}
              valueChange={portalExcludedCollections.setValue}
            >
              {(collection) => collection}
            </Autocomplete>
          </LabeledInput>
        </div>
        <div className={gridCss.span12}>
          <FormSwitch
            input={allowReopen}
            label="Allow reopening returns and claims after processing"
          >
            This feature should be enabled in very few circumstances. Do not
            enabled without good reason.
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <FormSwitch
            input={hideGiftCardBranding}
            label="Hide Redo gift card branding"
          >
            changes the first four digits of a gift card/code from REDO to four
            random characters
          </FormSwitch>
        </div>
        <div className={gridCss.span12}>
          <FormSwitch
            input={allowRestockBeforeProcess}
            label="Allow early restock"
          >
            Allow restocking returned items before the return has been
            processed. As of Nov. 2024, this feature is only supported for
            Shopify merchants.
          </FormSwitch>
        </div>
      </section>
    </Card>
  );
});
