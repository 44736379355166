// extracted by mini-css-extract-plugin
export var container = "_i-0";
export var error = "_i-9";
export var label = "_i-5";
export var light = "_i-6";
export var md = "_i-3";
export var sm = "_i-2";
export var thin = "_i-8";
export var thinBold = "_i-7";
export var vertical = "_i-4";
export var xs = "_i-1";