// extracted by mini-css-extract-plugin
export var active = "_14-a";
export var activeIndicator = "_14-9";
export var disabled = "_14-b";
export var fullWidth = "_14-2";
export var lg = "_14-7";
export var md = "_14-6";
export var redoButtonTabGroup = "_14-0";
export var sm = "_14-5";
export var tabButton = "_14-4";
export var tabButtonsContainer = "_14-1";
export var themeDestructive = "_14-d";
export var themeNormal = "_14-c";
export var themeSuccess = "_14-e";
export var xl = "_14-8";
export var xs = "_14-3";