import { useRequiredContext } from "@redotech/react-util/context";
import { Uploader, UploaderContext } from "@redotech/redo-web/image-upload";
import { ReactNode } from "react";
import { RedoAdminClient } from ".";
import { RedoAdminClientContext } from "./context";
import { createUpload } from "./team";

function clientUploader(client: RedoAdminClient): Uploader {
  return async (file, signal) => {
    const result = await createUpload(client, { file, signal });
    return new URL(result.url);
  };
}

export function ClientUploader({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoAdminClientContext);
  return (
    <UploaderContext.Provider value={clientUploader(client)}>
      {children}
    </UploaderContext.Provider>
  );
}
