// extracted by mini-css-extract-plugin
export var checkbox = "_w-0";
export var circle = "_w-3";
export var horizontalContainer = "_w-7";
export var sm = "_w-5";
export var switch0 = "_w-2";
export var switchDisabled = "_w-8";
export var switchEnabled = "_w-1";
export var textSwitchContainer = "_w-9";
export var textSwitchOption = "_w-a";
export var textSwitchOptionSelected = "_w-b";
export var verticalContainer = "_w-6";
export var xs = "_w-4";