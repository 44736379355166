import { object, string } from "zod";

export const getMultipassSecretSchema = {
  input: object({
    teamId: string(),
  }),
  output: object({
    multipassSecret: string().optional(),
  }),
};
