// extracted by mini-css-extract-plugin
export var box = "_16-0";
export var check = "_16-1";
export var checkboxButton = "_16-6";
export var checkboxGroup = "_16-5";
export var disabled = "_16-7";
export var ghost = "_16-9";
export var input = "_16-2";
export var label = "_16-4";
export var normal = "_16-8";
export var uncheck = "_16-3";